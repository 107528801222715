import styled from '@emotion/styled'
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import AuthButton from '../components/auth-button'
import AuthInput, {
  AuthInputError,
  AuthInputGroup
} from '../components/auth-input'
import AuthLink from '../components/auth-link'
import AuthTitle from '../components/auth-title'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  getAuth,
  sendSignInLinkToEmail,
  // signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  User
} from 'firebase/auth'
import { LoginFields, loginFirebase, LoginSchema, login } from '../api/auth'
import { useToken } from '../hooks/use-auth'
import axios from 'axios'
import { ErrorType } from '../api'

import { CC_PUBLIC_URL } from '../config'
import LogoGoogle from '../assets/svg/google_logo.svg'

const GoogleSigninButton = styled.button`
  display: flex;
  align-items: center;
  background-color: white;
  color: #4285f4;
  border: none;
  border-radius: 24px;
  padding: 10px 24px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;

  img {
    width: 20px;
    height: 20px;
    margin-right: 24px;
  }

  hover {
    background-color: #357ae8;
  }
`

const LoginForm = styled.form`
  padding-bottom: 20px;

  &__input {
    background: transparent !important;
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0px;
    border: none !important;
    border-bottom: 1px solid #fff !important;

    &.invalid {
      border-bottom-color: #bd271e !important;
    }

    &::placeholder {
      color: #bbbbbd;
    }
  }
  .euiFormHelpText.euiFormRow__text {
    font-size: 10px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #bbbbbd !important;
    letter-spacing: 0px;
  }

  input[placeholder='Choose password']::placeholder,
  input[placeholder='Confirm password']::placeholder {
    color: #fff !important;
  }
  .text {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0px;
    margin-bottom: 10px;
  }

  .error {
    color: #fd5206;
  }

  .brand-image-container,
  .login-title,
  .login-subtitle,
  .input-group-addon {
    display: none;
  }

  p.error {
    color: #fd5206;
    padding-bottom: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0px;
  }

  .error-message {
    text-align: left;
    margin-bottom: 10px;
  }
  .success-message {
    color: #00f86a;
    width: max-content;
  }
`

function LoginPage() {
  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
    setError
  } = useForm<LoginFields>({ resolver: yupResolver(LoginSchema) })
  const { setToken } = useToken()

  const [validateEmail, setValidateEmail] = useState(false)

  const verifyUser = (user: User) => {
    user.getIdToken().then(async (idToken: string) => {
      const res = await loginFirebase({
        body: idToken
      })
      setToken({
        token: `${res.tokenType} ${res.accessToken}`,
        save: true
      })
    })
  }

  const handleValidateEmail = handleSubmit(async (body) => {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: `https://${window.location.hostname}${CC_PUBLIC_URL}`,
      // This must be true.
      handleCodeInApp: true,
      dynamicLinkDomain: window.location.hostname
    }

    var firebaseAuth = getAuth()
    sendSignInLinkToEmail(firebaseAuth, body.username, actionCodeSettings)
      .then(() => {
        alert('Check your email, verify, and then try to login again')
        setValidateEmail(false)
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        setError('password', {
          message: `Error sending validation mail ${errorCode}: ${errorMessage}`
        })
      })
  })

  // const handleFirebasePasswordLogin = handleSubmit(async (body) => {
  //   var firebaseAuth = getAuth()
  //   var email = body.username
  //   var password = body.password

  //   firebaseAuth.onAuthStateChanged(async (user) => {
  //     if (user) {
  //       console.log(`We have a user ${JSON.stringify(user)}`)

  //       verifyUser(user)
  //     } else {
  //       console.log(`No user is signed in`)
  //     }
  //   })

  //   signInWithEmailAndPassword(firebaseAuth, email, password).catch((error) => {
  //     console.log(`There was an error signing in : ${error.message}`)
  //     setError('password', {
  //       message: error?.message || 'Something went wrong.'
  //     })
  //   })
  // })

  const handleOnSubmit = handleSubmit(async (body) => {
    try {
      const res = await login({
        body
      })
      setToken({
        token: `${res.tokenType} ${res.accessToken}`,
        save: true
      })
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setError('password', {
          message:
            (err?.response?.data as ErrorType)?.message ||
            'Something went wrong.'
        })
      }
    }
  })

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider()
      const firebaseAuth = getAuth()
      const currentUser = firebaseAuth.currentUser

      if (!currentUser) {
        signInWithPopup(firebaseAuth, provider).then((userCredential) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          verifyUser(userCredential.user)
        })
      } else {
        verifyUser(currentUser)
      }
    } catch (e) {
      console.log(`Error in popup sign in: ${e}`)
    }
  }

  return (
    <Fragment>
      <LoginForm
        onSubmit={!validateEmail ? handleOnSubmit : handleValidateEmail}
      >
        <AuthTitle>Sign in</AuthTitle>
        <GoogleSigninButton
          id="google-signin-button"
          onClick={handleGoogleLogin}
        >
          <img src={LogoGoogle} alt="Google logo" />
          Sign in with Google
        </GoogleSigninButton>
        <AuthLink to="/auth/forgot">
          Or sign in with email and password?
        </AuthLink>
        <AuthInputGroup>
          <AuthInput
            placeholder="Email"
            {...register('username')}
            autoComplete="username"
          />
          {errors.username && (
            <AuthInputError>{errors.username.message}</AuthInputError>
          )}
        </AuthInputGroup>
        <AuthInputGroup>
          <AuthInput
            placeholder="Password"
            type="password"
            {...register('password')}
            autoComplete="new-password"
          />
          {errors.password && (
            <AuthInputError>{errors.password.message}</AuthInputError>
          )}
        </AuthInputGroup>
        {!validateEmail ? (
          <div>
            <AuthButton type="submit" width="100%" disabled={isSubmitting}>
              Sign in
            </AuthButton>
          </div>
        ) : (
          <AuthButton type="submit" width="100%" disabled={isSubmitting}>
            Validate email
          </AuthButton>
        )}
      </LoginForm>

      <AuthLink to="/auth/forgot">Forgot password?</AuthLink>
    </Fragment>
  )
}

export default LoginPage
