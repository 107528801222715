import { ENV } from './globals'

const DEV_SERVER = 'dev'
const DEV_HOST = `${DEV_SERVER}.inoviaai.se`

window.ENV ||= {} as ENV

export const {
  CC_PUBLIC_URL = '',
  GATEWAY_URL = `https://${DEV_HOST}`,
  WEBSOCKET_DEV_URL = `wss://${DEV_HOST}/ws`,
  FIREBASE_API_KEY = 'AIzaSyDtgfI3VPxhIAEj3U-Q1b8CTKGgeBAwNhI',
  FIREBASE_AUTH_DOMAIN = 'inovia-dev.firebaseapp.com',
  SO_APP_ID = '182a523198438cd394e83d0f0f9dbd5c',
  SO_ENV = 'sod'
} = window.ENV
