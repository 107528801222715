import { atom, useAtom } from 'jotai'
import { jwtDecode } from 'jwt-decode'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import pkg from '../../package.json'
import { TokenUser } from '../api/auth'
import { USER_ROLES } from '../constants/keys'
import setDefaultAuthHeader from '../utils/set-default-auth-header'
import { useTenantId } from './use-tenant-id'

export const TOKEN_KEY = `${pkg.name}.token`
const USER_KEY = `${pkg.name}.user`

function getInitialToken(key: string) {
  try {
    const token = localStorage.getItem(key) ?? 'null'
    const user = jwtDecode<TokenUser>(token)

    const isTokenExpired = token && moment.unix(user.exp).isBefore(moment())
    if (isTokenExpired) {
      localStorage.removeItem(key)
      return null
    }
    return {
      user,
      token
    }
  } catch (error) {
    return null
  }
}

const initialToken = getInitialToken(TOKEN_KEY)?.token ?? ''

setDefaultAuthHeader(initialToken)

const tokenBaseAtom = atom(initialToken)
const tokenAtom = atom<string, { token: string; save?: boolean }>(
  (get) => get(tokenBaseAtom),
  (_, set, { token, save = false }) => {
    if (save) localStorage.setItem(TOKEN_KEY, token)
    set(tokenBaseAtom, token)

    setDefaultAuthHeader(token)
    if (token) {
      const user = jwtDecode<TokenUser>(token)
      set(userAtom, { user, save: true })
    }
  }
)

export const useIsAuthenticated = () => !!useAtom(tokenAtom)[0]
export const useToken = () => {
  const [token, setToken] = useAtom(tokenAtom)
  return { token, setToken }
}

type UserAtomType = TokenUser | null
const userBaseAtom = atom(
  JSON.parse(localStorage.getItem(USER_KEY) ?? 'null') as UserAtomType
)
const userAtom = atom<UserAtomType, { user: UserAtomType; save?: boolean }>(
  (get) => get(userBaseAtom),
  (get, set, { user, save = false }) => {
    if (save) localStorage.setItem(USER_KEY, JSON.stringify(user))
    set(userBaseAtom, user && { ...get(userBaseAtom), ...user })
  }
)
export const useUser = () => {
  const [user, setUser] = useAtom(userAtom)
  const isSuperAdmin = useMemo(
    () => user?.roles.includes(USER_ROLES.SUPER_ADMIN) || false,
    [user?.roles]
  )
  return { user, setUser, isSuperAdmin }
}

export const useLogout = () => {
  const setUser = useAtom(userAtom)[1]
  const setToken = useAtom(tokenAtom)[1]
  const { setTenantId } = useTenantId()

  return useCallback(() => {
    setToken({ token: '', save: true })
    setUser({ user: null, save: true })
    setTenantId('')
  }, [setTenantId, setToken, setUser])
}
