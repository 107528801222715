import * as React from 'react'

import AnalyticsPluginIcon from '@analytics-plugin/assets/icon_analytics_plugin.svg'
import DialogEditorIcon from '@dialog-editor/src/assets/dialog_editor.svg'
import EntityManagementIcon from '@entity-management/assets/entity_management_plugin.svg'
import InboxPluginIcon from '@inbox-plugin/assets/inbox_plugin.svg'
import KnowledgePluginIcon from '@knowledge-plugin/assets/icon_knowledge_plugin.svg'
import AssignmentsPluginIcon from '@assignments-plugin/assets/icon_knowledge_plugin.svg'
import ManagementPluginIcon from '@management-plugin/assets/management_plugin_icon.svg'
import StartPageIcon from '@start-page/assets/arrow_start_page.svg'
import TrainingPluginIcon from '@training-plugin/assets/training_plugin.svg'
import IntegrationIcon from '@integrations/assets/integrations.svg'

// import ClassifierTestIcon from '@classifier-test//dist/assets/icon.svg'
import { WEBSOCKET_DEV_URL } from './config'
import { USER_ROLES } from './constants/keys'
import PATHS from './constants/paths'
import { useTenantId } from './hooks/use-tenant-id'

export type PluginNames =
  | 'dialog-editor'
  | 'inbox-plugin'
  | 'training-plugin'
  | 'analytics-plugin'
  | 'validation-plugin'
  | 'management-plugin'
  | 'start-page'
  | 'entity-management'
  | 'knowledge-plugin'
  | 'assignments-plugin'
  | 'integrations'

const CC_ENABLED_PLUGINS = process.env.CC_ENABLED_PLUGINS || ''
const enabledPlugins = CC_ENABLED_PLUGINS.split(',') as PluginNames[]

export const pluginComponents = {} as Record<
  PluginNames,
  {
    name: string
    Component: React.LazyExoticComponent<React.ComponentType<any>>
    icon: string
    hiddenForRoles: string[]
    tenantIds?: string[]
  }
>
pluginComponents['assignments-plugin'] = {
  name: 'Assignments',
  Component: React.lazy(() => import('@assignments-plugin/index')),
  icon: AssignmentsPluginIcon,
  hiddenForRoles: [],
  tenantIds: ['af24b5e3-71cb-4ab5-99fa-f2b0ab0b8f32']
}

pluginComponents['dialog-editor'] = {
  name: 'Dialog editor',
  Component: React.lazy(() => import('@dialog-editor/index')),
  icon: DialogEditorIcon,
  hiddenForRoles: [USER_ROLES.AGENT]
}

pluginComponents['inbox-plugin'] = {
  name: 'Inbox',
  Component: React.lazy(() => import('@inbox-plugin/index')),
  icon: InboxPluginIcon,
  hiddenForRoles: []
}
pluginComponents['training-plugin'] = {
  name: 'AI Training',
  Component: React.lazy(() => import('@training-plugin/index')),
  icon: TrainingPluginIcon,
  hiddenForRoles: [USER_ROLES.AGENT]
}
pluginComponents['analytics-plugin'] = {
  name: 'Analytics',
  Component: React.lazy(() => import('@analytics-plugin/index')),
  icon: AnalyticsPluginIcon,
  hiddenForRoles: []
}
pluginComponents['validation-plugin'] = {
  name: 'Validation',
  Component: React.lazy(() => import('@validation-plugin/index')),
  icon: TrainingPluginIcon,
  hiddenForRoles: []
}
pluginComponents['management-plugin'] = {
  name: 'Management',
  Component: React.lazy(() => import('@management-plugin/index')),
  icon: ManagementPluginIcon,
  hiddenForRoles: [USER_ROLES.AGENT]
}
pluginComponents['start-page'] = {
  name: 'Home',
  Component: React.lazy(() => import('@start-page/index')),
  icon: StartPageIcon,
  hiddenForRoles: []
}
pluginComponents['entity-management'] = {
  name: 'Entities',
  Component: React.lazy(() => import('@entity-management/index')),
  icon: EntityManagementIcon,
  hiddenForRoles: [USER_ROLES.AGENT]
}
pluginComponents['knowledge-plugin'] = {
  name: 'Knowledge',
  Component: React.lazy(() => import('@knowledge-plugin/index')),
  icon: KnowledgePluginIcon,
  hiddenForRoles: [USER_ROLES.AGENT]
}

pluginComponents['integrations'] = {
  name: 'Integrations',
  Component: React.lazy(() => import('@integrations/index')),
  icon: IntegrationIcon,
  hiddenForRoles: [USER_ROLES.AGENT]
}

function InjectProps({
  Component,
  pathname
}: {
  Component: React.LazyExoticComponent<React.ComponentType<any>>
  pathname: string
}) {
  const tenantIdProps = useTenantId()

  return (
    <Component
      {...tenantIdProps}
      isDevelopment={process.env.NODE_ENV === 'development'}
      websocketDevUrl={WEBSOCKET_DEV_URL}
      pathname={pathname}
    />
  )
}

const plugins = enabledPlugins.map((pluginName) => {
  console.log(pluginName)
  const { Component, ...rest } = pluginComponents[pluginName]

  return {
    path: pluginName,
    element: (
      <InjectProps Component={Component} pathname={`plugin/${pluginName}`} />
    ),
    ...rest
  }
})

export const startPage = plugins.find(
  (plugin) => plugin.path === PATHS.START_PAGE
)

export default plugins.filter((plugin) => plugin.path !== PATHS.START_PAGE)
