import { EuiPopover, EuiPopoverTitle } from '@elastic/eui'
import styled from '@emotion/styled'
import { Fragment, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useTenantId } from 'src/hooks/use-tenant-id'
import helpSvg from '../../assets/svg/helpIcon.svg'
import LogoColored from '../../assets/svg/logo_colored.svg'
import userSvg from '../../assets/svg/user.svg'
import colors from '../../colors'
import { useLogout, useUser } from '../../hooks/use-auth'
import plugins from '../../plugins'
import ConfirmationModalButton from '../confirmation-modal-button'
import { LinkButton } from '../link-button'

const LogoColoredImg = styled.img`
  width: 100%;
`

const SidebarImg = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 12px;
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
`

const SidebarBottomContainer = styled.div`
  border-top: 1px solid #62b4f7;
  padding-top: 15px;
  margin-top: auto;
  margin-bottom: 20px;
  text-align: left;
`

const SidebarDiv = styled.div<{
  mainWidth: string
  mainTop: string
}>`
  background-color: ${colors.navy};
  padding: 12px;
  position: fixed;
  width: ${({ mainWidth }) => mainWidth};
  top: ${({ mainTop }) => mainTop};
  bottom: 0;
  display: flex;
  flex-direction: column;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;

  a {
    font-size: 14px;
    font-weight: 400;
    display: block;
    color: ${colors.lightBlue};
    padding: 10px 0;
    display: flex;
    align-items: center;
    font-family: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  ${LinkButton} {
    color: ${colors.lightBlue};
    display: flex;
  }

  .active {
    color: #fff !important;
    filter: brightness(5);
  }
`

const PopoverBody = styled.div`
  width: 200px;

  .button_logout {
    padding-top: 30px;
    text-align: right;

    .euiButton {
      background-color: #fff;
      box-shadow: none;

      &:hover {
        background-color: rgba(242, 212, 210, 0.4);
        box-shadow: 0 3px 5px 1px rgb(179 97 77 / 30%);
      }
    }
  }
`

function Sidebar({ mainWidth }: { mainWidth: string }) {
  const logOut = useLogout()
  const [openUserPopUp, setOpenUserPopUp] = useState(false)

  const handleHelpBtnPress = () =>
    setOpenUserPopUp((openUserPopUp) => !openUserPopUp)
  const closePopover = () => setOpenUserPopUp(false)

  const { user, isSuperAdmin } = useUser()
  const { tenantId } = useTenantId()
  return (
    <SidebarDiv mainWidth={mainWidth} mainTop={isSuperAdmin ? '40px' : '0px'}>
      <div style={{ height: 'auto' }}>
        <Link to="/">
          <LogoColoredImg src={LogoColored} />
        </Link>
        {plugins.map((plugin) => {
          let isPluginHidden = user?.roles.some((role) =>
            plugin.hiddenForRoles.includes(role)
          )
          if (
            plugin.tenantIds?.length &&
            plugin.tenantIds.indexOf(tenantId) < 0
          ) {
            isPluginHidden = true
          }

          return isPluginHidden ? (
            <Fragment key={plugin.path} />
          ) : (
            <NavLink key={plugin.path} to={plugin.path}>
              <SidebarImg src={plugin.icon} alt={plugin.name} /> {plugin.name}
            </NavLink>
          )
        })}
      </div>
      <SidebarBottomContainer>
        <EuiPopover
          ownFocus
          button={
            <LinkButton onClick={handleHelpBtnPress}>
              <SidebarImg src={userSvg} alt="Help" />
              {user?.firstname}
            </LinkButton>
          }
          isOpen={openUserPopUp}
          closePopover={closePopover}
          anchorPosition="rightDown"
        >
          <PopoverBody>
            <EuiPopoverTitle>
              {user?.firstname} {user?.lastname}
            </EuiPopoverTitle>
            <div className="button_logout">
              <ConfirmationModalButton
                size="s"
                color="warning"
                asyncFunc={logOut}
              >
                Sign out
              </ConfirmationModalButton>
            </div>
          </PopoverBody>
        </EuiPopover>
        <a target="_blank" href="https://inoviaai.com/docs" rel="noreferrer">
          <SidebarImg src={helpSvg} alt="Help" /> Help
        </a>
      </SidebarBottomContainer>
    </SidebarDiv>
  )
}

export default Sidebar
